import React from "react";
import './Sidebar.css';
import { AiOutlineHome } from "react-icons/ai";
import logo from "../Images/logo.webp";
import { IoTimeOutline } from "react-icons/io5";
import { BsBookmark,BsCalendarCheck,BsStar } from "react-icons/bs";
import { GiBlackBook } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";



const Sidebar = ({isClass,setClassName}) => {
    return (
        <>
          <aside className={` Sidebar-Navigation ${isClass ? 'nav-slided' : '' } `}>
          <div className="header-section__item sidebar">
                <img src={logo} className="mob-logo"  alt="logo" title="logo" height={124} width={120} />
            </div>
             <ul className="Sidebar-Navigation__List">
                <li className="Sidebar-Navigation__List--item"> 
                <Link to='/' title="Home">
                   <div className="Sidebar-Navigation__ListItem">
                     <AiOutlineHome />
                     <span className="Sidebar-Navigation__Item menusName" onClick={setClassName}>Home</span>
                     <span onClick={setClassName} className='crossIcon' >
                     <RxCross1 />
                     </span>
                   </div>
                   </Link>
                </li>
                <li className="Sidebar-Navigation__List--item"> 
                  <div className="Sidebar-Navigation__ListItem">
                    <IoTimeOutline />
                    <span className="menusName">Time-Table</span>
                  </div>
                  <ul className="Sidebar-Navigation__SubList">
                       <li className="Sidebar-Navigation__SubListItems" onClick={setClassName}>
                       <Link to='selectmonthtt' title="Namaz Time-Table">
                       <span className="Sidebar-Navigation__Item menusName">Namaz Time-Table</span>
                       </Link>
                       </li>
                   
                    <li className="Sidebar-Navigation__SubListItems" onClick={setClassName}>
                    <Link to='selectmonthsi' title="Sehri & Iftaar">
                    <span className="Sidebar-Navigation__Item menusName">Sehri & Iftaar</span>
                    </Link>
                    </li>
                  
                  </ul>
                </li>
                <li className="Sidebar-Navigation__List--item">
                 <div className="Sidebar-Navigation__ListItem">
                    <BsStar />
                    <span className="menusName">Ibadat</span>
                  </div>     
                  <ul className="Sidebar-Navigation__SubList">
                   
                    <li className="Sidebar-Navigation__SubListItems" onClick={setClassName}>
                    <Link to='ramzanulmubarak' title="Ramzan-Ul-Mubarak">
                    <span className="Sidebar-Navigation__Item menusName">Ramzan-Ul-Mubarak</span>
                    </Link>
                    </li>
                   
                  
                    <li className="Sidebar-Navigation__SubListItems"  onClick={setClassName}>
                    <Link to='shabeqadr' title="Shab-E-Qadr">
                    <span className="Sidebar-Navigation__Item menusName">Shab-E-Qadr</span>
                    </Link>
                    </li>
                    
                   
                    <li className="Sidebar-Navigation__SubListItems" onClick={setClassName}>
                    <Link to='shabebarat' title="Shab-E-Baaraat">
                    <span className="Sidebar-Navigation__Item menusName">Shab-E-Baaraat</span>
                    </Link>
                    </li>
                    
                  </ul>
                </li>
                
                  <li className="Sidebar-Navigation__List--item" onClick={setClassName}> 
                  <Link to='esalesawab' title="Esal-E-Sawab">
                    <div className="Sidebar-Navigation__ListItem">
                      <BsBookmark />
                      <span className="Sidebar-Navigation__Item menusName">Esal-E-Sawab</span>
                    </div>
                    </Link>
                  </li>
                
               
                  <li className="Sidebar-Navigation__List--item" onClick={setClassName}> 
                  <Link to='ramzancalendar' title="Ramzan calendar">
                    <div className="Sidebar-Navigation__ListItem">
                      <BsCalendarCheck />
                      <span className="menusName">Ramzan Calendar</span>
                      <span className="calendarYear">2025</span>
                    </div>
                    </Link>
                  </li>
                
              
                  <li className="Sidebar-Navigation__List--item" onClick={setClassName}> 
                  <Link to='miladeakbar' title="Milade Akbar">
                    <div className="Sidebar-Navigation__ListItem">
                      <GiBlackBook /> 
                      <span className="menusName">Milade Akbar</span>
                    </div>
                    </Link>
                  </li>
               
                {/* <li className="Sidebar-Navigation__List--item"> 
                 <div className="Sidebar-Navigation__ListItem">
                    <IoCallOutline />
                    <span className="menusName">Contact</span>
                 </div>
                </li> */}
             </ul>
          </aside>
        </>
    )
}

export default Sidebar;