import React from 'react'
import { CiFacebook } from "react-icons/ci";
import { IoLogoInstagram } from "react-icons/io";

const Footer = () => {
  return (
    <footer className="Footer">
    <div className="Footer_Item--left">
       <span>Follow Us : </span>
       <div className="Footer__social-icon">
          <a href="https://www.facebook.com/talabulilm.in/" rel='noreferrer' target="_blank" title="facebook" aria-label="facebook">
          <CiFacebook size={30} />
          </a>
          <a href="https://www.instagram.com/talabulilm.in/" rel='noreferrer' target="_blank" title="instagram" aria-label="instagram">
          <IoLogoInstagram size={30} />
          </a>
       </div>
    </div>
    <div className="Footer_Item--right">
    <p>| talabulilm.in</p>
    </div>
 </footer>
  )
}

export default Footer