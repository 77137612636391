import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const JuneTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={6} title="June Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default JuneTimeTable;
