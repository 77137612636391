import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const AprilTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={4} title="April Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default AprilTimeTable;
