import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const FebruaryTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={2} title="February Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default FebruaryTimeTable;
