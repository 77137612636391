import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { MdArrowBackIosNew } from "react-icons/md";
import FullTimeTable from "./FullTimeTable";
import { allColumns } from "./Columns";

import './TimeTable.css';

const MonthlyTimeTable = ({ month, title, columnKey = "12Columns", districtDetails  }) => {
  const [filtertimes, setFilterTimes] = useState([]);
  const currentYear = new Date().getFullYear(); 
  const { district, state } = districtDetails;


  useEffect(() => {
    if (!districtDetails) return;

    // Fetch data based on the district
    const districtData = FullTimeTable[districtDetails.district] || [];

    const transformedData = districtData
      .map((item) => ({
        ...item,
        date: `${item.date}-${currentYear}`,
      }))
      .filter(item => {
        const dateParts = item.date.split('-'); 
        const day = parseInt(dateParts[0], 10); 
        const itemMonth = dateParts[1];
  
        return itemMonth === String(month) && day >= 1 && day <= 31;
      });

    setFilterTimes(transformedData);
  }, [currentYear, month, districtDetails]); 

  // Dynamically select columns based on the key
  const tableColumns = allColumns[columnKey] || allColumns["12Columns"];

  return (
    <div className='contain'>
      <div className="page-header">
        <span className="backBtn" onClick={() => window.history.back()}>
          <MdArrowBackIosNew />
        </span>
        <div className="page-header__content">
          <h2 className="pagetitle">{title}</h2>
          {/* <p>Lucknow, Uttar Pradesh, INDIA</p> */}
         <p>{district}{state ? `, ${state}` : ''}, INDIA</p>   

        </div>
      </div>
      <div className="contentSection-card">
        <DataTable 
          columns={tableColumns} 
          data={filtertimes} 
          fixedHeader
          fixedHeaderScrollHeight='460px'
          selectableRowsHighlight  
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default MonthlyTimeTable;
