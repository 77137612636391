import React, { useState } from "react";
import TopHeader from "../Components/TopHeader/TopHeader";
import MainLayout from "../Components/MainLayout/MainLayout";
import Footer from "../Components/Footer/Footer";

const Layout = ({isClass, setClassName, darkMode}) => {
  const [districtDetails, setDistrictDetails] = useState({
    district: localStorage.getItem("selectedDistrict") || "Lucknow",
    state: localStorage.getItem("selectedDistrictState") || "Uttar Pradesh",
  });

  const handleDistrictChange = (details) => {
    // Update state only if the district or state has changed
    if (
      details.district !== districtDetails.district ||
      details.state !== districtDetails.state
    ) {
      setDistrictDetails(details);
    }
  };
  
    return(
        <>
          <TopHeader setClassName={setClassName} darkMode={darkMode} onDistrictChange={handleDistrictChange} />
          <MainLayout isClass={isClass} setClassName={setClassName} districtDetails={districtDetails} />
          <Footer />
        </>
    )
}

export default Layout;          