import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const SepTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={9} title="September Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default SepTimeTable;
