import React, { useEffect, useState } from "react";
import FullTimeTable from "../TimeTable/FullTimeTable";

const DailyTime = ({ districtDetails }) => {
  const [currentTime, setCurrentTime] = useState(formatTimeWithSeconds(new Date()));
  
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(formatTimeWithSeconds(now)); // Update the current time every second
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  // Function to format the time with hours, minutes, and seconds
  function formatTimeWithSeconds(time) {
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }
  
  // const getTimeLeft = (current, nextTime) => {
  //   if (!nextTime) return "Invalid time";

  //   // Parse the current and next prayer time
  //   const currentTime = new Date(`01/01/2000 ${current}`);
  //   const nextPrayerTime = new Date(`01/01/2000 ${nextTime}`);

  //   const timeDifference = nextPrayerTime - currentTime;

  //   // If the prayer time has passed
  //   if (timeDifference <= 0) {
  //     return "Time up";
  //   }

  //   // Calculate time components
  //   const secondsLeft = Math.floor(timeDifference / 1000); // Total seconds remaining
  //   const hoursLeft = Math.floor(secondsLeft / 3600); // Convert to hours
  //   const minutesLeft = Math.floor((secondsLeft % 3600) / 60); // Get remaining minutes
  //   const secondsRemaining = secondsLeft % 60; // Get remaining seconds

  //   // Format as HH:MM:SS with leading zeros
  //   return `${hoursLeft.toString().padStart(2, "0")}:${minutesLeft.toString().padStart(2, "0")}:${secondsRemaining.toString().padStart(2, "0")}`;
  // };
  // Function to convert time (AM/PM) to minutes for comparison
  
  
  const getTimeLeft = (current, nextTime) => {
    if (!nextTime) return "Invalid time";
  
    // Parse the current and next prayer time
    const currentTime = new Date(`01/01/2000 ${current}`);
    const nextPrayerTime = new Date(`01/01/2000 ${nextTime}`);
  
    const timeDifference = nextPrayerTime - currentTime;
  
    // If the prayer time has passed
    if (timeDifference <= 0) {
      return "Time up";
    }
  
    // Calculate time components
    const secondsLeft = Math.floor(timeDifference / 1000); // Total seconds remaining
    const hoursLeft = Math.floor(secondsLeft / 3600); // Convert to hours
    const minutesLeft = Math.floor((secondsLeft % 3600) / 60); // Get remaining minutes
    const secondsRemaining = secondsLeft % 60; // Get remaining seconds
  
    // Format output based on time left
    if (hoursLeft > 0) {
      return `${hoursLeft.toString().padStart(2, "0")}:${minutesLeft.toString().padStart(2, "0")}:${secondsRemaining.toString().padStart(2, "0")} Hrs`;
    } else if (minutesLeft > 0) {
      return `${minutesLeft.toString().padStart(2, "0")}:${secondsRemaining.toString().padStart(2, "0")} Mints`;
    } else {
      return `${secondsRemaining.toString().padStart(2, "0")} Sec`;
    }
  };
  const convertTo24hrFormat = (time) => {
    const [timeStr, modifier] = time.split(" ");
    let [hours, minutes] = timeStr.split(":").map(Number);
    if (modifier === "PM" && hours < 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;
    return hours * 60 + minutes; // Convert to minutes
  };

  // Function to check if current time is within the prayer start and end times
  const isActiveTime = (currentTime, start, end) => {
    const currentMinutes = convertTo24hrFormat(currentTime);
    const startMinutes = convertTo24hrFormat(start);
    const endMinutes = convertTo24hrFormat(end);

    // Check if the current time is between start and end times (inclusive)
    return currentMinutes >= startMinutes && currentMinutes < endMinutes;
  };

  
  const [district, setDistrict] = useState(districtDetails.district);
  useEffect(() => {
    setDistrict(districtDetails.district);
  }, [districtDetails]);

  const selectedDistrictData = FullTimeTable[district];
  if (!selectedDistrictData) {
    return <p>Currently No data available for {district}</p>;
  }
  
  const current = new Date();
  const moonDate = `${current.getDate()}-${current.getMonth() + 1}`;

  return (
    <>
      {selectedDistrictData.map((items, index) => {
        return (
          <div key={index} className="contentSection-cardParent">
            {moonDate === items?.date && (
              <div className="contentSection-card dailyTime">
                {items.sfks && (
                  <div className={`dailyTime__item ${isActiveTime(currentTime, items.sfks, items.kfta) ? 'active' : ''}`} data-key="1">
                     <span className="emptyTag"></span>
                    <p>
                      <span>Khatm-E-Saher</span> / Shuru-E-Fajr
                    </p>
                    <span className="dailyTime__item--namaz-time">
                      {items.sfks} 
                    </span>
                    {isActiveTime(currentTime, items.sfks, items.kfta) && (
                    <p className="timeLeft"> 
                      <span>Time Left: </span>
                      <span className="withTime">{getTimeLeft(currentTime, items.kfta)} </span>
                    </p>
                    )}
                  </div>
                )}
                {items.kfta && (
                  <div className={`dailyTime__item ${isActiveTime(currentTime, items.kfta, items.szza) ? 'active' : ''}`} data-key="2">
                     <span className="emptyTag"></span>
                    <p>
                      <span>Tulu-E-Aftab</span> / Khatm-E-Fajr
                    </p>
                    <span className="dailyTime__item--namaz-time">
                      {items.kfta}
                    </span>
                    {isActiveTime(currentTime, items.kfta, items.szza) && (
                    <p className="timeLeft"> 
                      <span>Zohar after: </span>
                      <span className="withTime">{getTimeLeft(currentTime, items.szza)}</span>
                    </p>
                    )}
                  </div>
                )}
                {items.szza && (
                  <div className={`dailyTime__item ${isActiveTime(currentTime, items.szza, items.kzsa) ? 'active' : ''}`} data-key="3">
                      {/* <div className="dailyTime__item"> */}
                      <span className="emptyTag"></span>
                    <p>
                      Zawal-E-Aftab / <span>Shuru-E-Zohar</span>
                    </p>
                    <span className="dailyTime__item--namaz-time">
                      {items.szza}
                    </span>
                    {isActiveTime(currentTime, items.szza, items.kzsa) && (
                    <p className="timeLeft"> 
                      <span>Time Left: </span>
                      <span className="withTime">{getTimeLeft(currentTime, items.kzsa)}</span>
                    </p>
                    )}
                  </div>
                )}
                {items.kzsa && (
                   <div className={`dailyTime__item ${isActiveTime(currentTime, items.kzsa, items.gawi) ? 'active' : ''}`} data-key="4">
                     <span className="emptyTag"></span>
                    <p>
                      Khatm-E-Zohar / <span>Shuru-E-Asar</span>
                    </p>
                    <span className="dailyTime__item--namaz-time">
                      {items.kzsa}
                    </span>
                    {isActiveTime(currentTime, items.kzsa, items.gawi) && (
                    <p className="timeLeft"> 
                      <span>Time Left: </span>
                      <span className="withTime">{getTimeLeft(currentTime, items.gawi)}</span>
                    </p>
                    )}
                  </div>
                )}
                {items.gawi && (
                  <div className={`dailyTime__item ${isActiveTime(currentTime, items.gawi, items.kmsi) ? 'active' : ''}`} data-key="5">
                    <span className="emptyTag"></span>
                    <p>
                      Ghuroob-E-Aftab / <span>Waqt-E-Iftar</span> 
                    </p>
                    <span className="dailyTime__item--namaz-time">
                      {items.gawi}
                    </span>
                    {isActiveTime(currentTime, items.gawi, items.kmsi) && (
                    <p className="timeLeft"> 
                      <span>Time Left: </span>
                      <span className="withTime">{getTimeLeft(currentTime, items.kmsi)}</span>
                    </p>
                    )}
                  </div>
                )}
                {items.kmsi && (
                  <div className={`dailyTime__item ${isActiveTime(currentTime, items.kmsi, '11:59 PM') ? 'active' : ''}`} data-key="6">
                  <span className="emptyTag"></span>
                    <p>
                      Khatm-E-Maghrib / <span>Shuru-E-Isha</span>
                    </p>
                    <span className="dailyTime__item--namaz-time">
                      {items.kmsi}
                    </span>
                    {/* {isActiveTime(currentTime, items.kmsi, '11:59 PM') && (
                      <p className="timeLeft"> 
                        <span>Time Left: </span>
                        <span className="withTime">{getTimeLeft(currentTime, '11:59 PM')}</span>
                      </p>
                    )} */}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default DailyTime;
