import React, { useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md';
import data from './data';

const BetiKiAzmat = () => {
  
  const[language,setLanguage]=useState('engLang');

  const handleLanguageChange  = (event) => {
    setLanguage(event.target.value);
  }

  return (
      <div className='contain'>
           <div className="page-header">
              <span className="backBtn" onClick={()=> window.history.back()}>
                <MdArrowBackIosNew/>
              </span>
              <div className="page-header__content">
                <h2 className="pagetitle">Islam Me Betiyon Ka Maqam aur Unke Huqooq</h2>
              </div>
           </div>
           <div className="contentSection-card">
           <p className="contentSection_title--content mb-20">
            Islam ek mukammal zindagi ka nizaam hai jo insaniyat ke har pehlu ko bahut tafseel ke sath samjhata hai. Islam ne betiyon ko na sirf izzat aur maqam diya, balke unki har zarurat ka bhi khyal rakha.Nabi-E-Kareem <span className='fs-16'>ﷺ </span>ne betiyon ke hawale se kayi irshadaat bayan farmaye hain. Betiyon ki paidaish se leker unki tarbiyat, muhabbat, shaadi aur unke huqooq tak islam ne roushan rahnumai ki hai.neeche kuch ahaades aur Qur'an ki roushni me betiyon ki ahmiyat bayan ki gayi hai.
            </p>
            <div className='changeLangSection'>
            <label htmlFor="language">Change Language:</label>
              <select name="language" id="language" value={language} onChange={handleLanguageChange}>
                <option value="engLang">Default</option>
                <option value="urduLang">Urdu</option>  
                <option value="hindiLang">Hindi</option>  
              </select>
           </div>
           {data[`${language}`].map((item,index)=>{
               return (
                    <div className={`contentSection-card ${language === 'urduLang' ? 'urduLangActive' : ''}`} key={item.index}>
                    {item.subHeading && <h4 className="contentSection_subheading mt-0" dangerouslySetInnerHTML={{ __html: item.subHeading }}></h4> }
                    {item.content &&  <p className="contentSection_title--content" dangerouslySetInnerHTML={{ __html: item.content }}></p> }
                    {item.reference && <span className="referenceTag">{item.reference}</span>}
                    {item.subHeading2 && <h4 className="contentSection_subheading" dangerouslySetInnerHTML={{ __html: item.subHeading2 }}></h4> }
                    {item.content2 && <p className="contentSection_title--content" dangerouslySetInnerHTML={{ __html: item.content2 }}></p> }
                    {item.reference2 && <span className="referenceTag">{item.reference2}</span> }
                    {item.subHeading3 && <h4 className="contentSection_subheading" dangerouslySetInnerHTML={{ __html: item.subHeading3 }}></h4> }
                    {item.content3 &&  <p className="contentSection_title--content" dangerouslySetInnerHTML={{ __html: item.content3 }}></p> }
                    {item.reference3 &&  <span className="referenceTag">{item.reference3}</span> }
                    {item.subHeading4 && <h4 className="contentSection_subheading" dangerouslySetInnerHTML={{ __html: item.subHeading4 }}></h4> }
                    {item.content4 &&  <p className="contentSection_title--content" dangerouslySetInnerHTML={{ __html: item.content4 }}></p> }
                    {item.reference4 &&  <span className="referenceTag">{item.reference4}</span> }
                    </div>
               ) 
            })}
           </div>
            
      </div>
  )
}

export default BetiKiAzmat