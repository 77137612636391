import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const OctTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={10} title="October Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default OctTimeTable;
