import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const MayTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={5} title="May Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default MayTimeTable;
