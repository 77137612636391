import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import './RamzanCalendar.css';

function RamzanCalendar() {
  return (
    <div className='contain'>
        <div className="page-header">
          <span className="backBtn" onClick={()=> window.history.back()}>
            <MdArrowBackIosNew />
          </span>
          <div className="page-header__content">
            <h2 className="pagetitle">Ramzan Calendar 2025</h2>
          </div>
       </div>
       <div className="contentSection">
       <div className='calendar-section'>
            <div className='contentSection-card'>
              <h2>Coming soon</h2>
            </div>
         </div>
         </div>
    </div>
  )
}

export default RamzanCalendar