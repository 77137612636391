import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const JulyTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={7} title="July Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default JulyTimeTable;
