import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const NovTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={11} title="November Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default NovTimeTable;
