import React, { useEffect, useState } from "react";
import logo from "../Images/logo.webp";
import './TopHeader.css';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { RiMoonLine, RiSunLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const TopHeader = ({setClassName,darkMode, onDistrictChange })=> {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState(() => {
      const storedDistrict = localStorage.getItem("selectedDistrict") || "Lucknow";
  
      // Set the state based on the district or default to "Uttar Pradesh" if district is Lucknow
      const storedState = localStorage.getItem("selectedDistrictState") || (storedDistrict === "Lucknow" ? "Uttar Pradesh" : "");
        return {
          district: storedDistrict,
          state: storedState,
        };
      });
      const [showConfirm, setShowConfirm] = useState(false); // To show the modal
      const [tempDistrict, setTempDistrict] = useState(selectedDistrict);  

    useEffect(() => {
      // Notify parent about the initially selected district and state
      if (onDistrictChange) {
        onDistrictChange(selectedDistrict);
      }
    }, []); // Run only on mount
  const darkModeIcon = () => {
    setIsDarkMode(!isDarkMode);
  };
  const handleDarkModeClick = () => {
    darkMode();
    darkModeIcon();
  };

  // const handleChange = (event) => {
  //   const selectedOption = event.target.options[event.target.selectedIndex];
  //   const district = selectedOption.value;
  //   const state = selectedOption.getAttribute('data-state');
  //   // console.log(`${district},${state}`);
  //   setSelectedDistrict({ district, state }); 
  //   onDistrictChange({ district, state });
  // };
  const handleChange = (e) => {
    const newDistrict = e.target.value;
    const newState = e.target.options[e.target.selectedIndex].getAttribute("data-state") || "";

    // Temporarily set the new district for confirmation
    setTempDistrict({ district: newDistrict, state: newState });
    setShowConfirm(true); // Show custom confirmation dialog
  };
  const handleConfirm = (isPermanent) => {
    if (isPermanent) {
      // Save the district permanently in localStorage
      localStorage.setItem("selectedDistrict", tempDistrict.district);
      localStorage.setItem("selectedDistrictState", tempDistrict.state);
    }
    // Always update the state to reflect the change
    setSelectedDistrict(tempDistrict);

    // Notify parent component about the district change
    if (onDistrictChange) {
      onDistrictChange(tempDistrict);
    }

    setShowConfirm(false); // Close the modal
  };

  
    return (
       <>
        <header className="header-section">
        <a href="#main" className="skip">Skip to main content</a>
            <Link to='/'>
            <div className="header-section__item">
                <img src={logo} className="logo"  alt="logo" title="logo" width={110} height={79} />
            </div>
            </Link>
            <div className="header-section__item2">
                <div className="header-section__hadees">
                        <p className='urduFont urduHadees'>طَلَبُ الْعِلْمِ فَرِيضَةٌ عَلَى كُلِّ مُسْلِمٍ</p> 
                 </div>
                 <div className='changeDist'>
                  <label htmlFor="district">Location : </label>
                    <select name="district" id="district" value={selectedDistrict.district} onChange={handleChange}>
                      <option value="Lucknow" data-state="Uttar Pradesh" disabled={selectedDistrict.district === "Lucknow"}>Lucknow</option>
                      <option value="Delhi" data-state="" disabled={selectedDistrict.district === "Delhi"}>Delhi</option>  
                      <option value="Pilibhit" data-state="Uttar Pradesh" disabled={selectedDistrict.district === "Pilibhit"}>Pilibhit</option>  
                    </select>
                </div>
                {showConfirm && (
                        <div className="custom-modal">
                          <div className="modal-content ani-move-down">
                             <div className="text-center"><span className="icon-info"><IoIosInformationCircleOutline size={20}/></span></div>
                             <p className="text-center my-10 fs-20">Confirm</p>
                              <p className="msg">Do you want to set <b>{tempDistrict.district}</b> as the default district/place ?</p>
                             <div className="btn-groups">
                              <button onClick={() => handleConfirm(true)} className="btn btn-confirm">Yes</button>
                              <button onClick={() => handleConfirm(false)} className="btn btn-cancel">No</button>
                             </div>
                          </div>
                        </div>
                    )}
                
               <div className="d-flex">
               <span className="moonIcon" onClick={handleDarkModeClick}>
                 {isDarkMode ? <RiSunLine /> : <RiMoonLine />}
                </span>
                <span className="hamburgerIcon" onClick={setClassName}><GiHamburgerMenu /></span>
                <input type="text" placeholder="Serch here" className="searchInput"/>
               </div>
            </div>
        </header>
       </>
    )
}

export default TopHeader;
