import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const DecTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={12} title="December Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default DecTimeTable;
