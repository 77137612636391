export const allColumns = {
   "12Columns" : [
    { name: 'Date', selector: row => row.date,
      style: {
         position: 'sticky',
         left: 0,
         zIndex: 1,
         backgroundColor: '#fff',
         boxShadow: 'rgba(149, 157, 165, 0.1) 0px 8px 24px',
         minWidth:'80px !important'
     },
     headerStyle: {
       position: 'sticky',
       left: 0,
       zIndex: 2, 
       backgroundColor: '#fff',
       boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
     },
    },
    { name: 'Shuru-E-Fajr/Khatm-E-Saher', selector: row => row.sfks },
    { name: 'Khatm-E-Fajr/Tulu-E-Aftab', selector: row => row.kfta },
    { name: 'Shuru-E-Zohar/Zawal-E-Aftab', selector: row => row.szza },
    { name: 'Khatm-E-Zohar/Shuru-E-Asar', selector: row => row.kzsa },
    { name: 'Ghuroob-E-Aftab/Waqt-E-Iftar', selector: row => row.gawi },
    { name: 'Khatm-E-Maghrib/Shuru-E-Isha', selector: row => row.kmsi }
   ],
   "3Columns" : [
    { name: 'Date', selector: row => row.date},
    { name: 'Shuru-E-Fajr/Khatm-E-Saher', selector: row => row.sfks },
    { name: 'Ghuroob-E-Aftab/Waqt-E-Iftar', selector: row => row.gawi },
   ],
};
