import React from "react";
import MonthlyTimeTable from "./MonthlyTimeTable";



const MarchTimeTable = ({districtDetails }) => {
  return <MonthlyTimeTable month={3} title="March Namaz Time Table" columnKey="12Columns" districtDetails={districtDetails} />;
};

export default MarchTimeTable;
